import { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import Homepage from "../Homepage";
import Login from "./index";
import { GET_USER_TOKENS, USER } from "./gql";
import moment from "moment";
import { useBoundStore } from "../../utils/stores/BoundStore";
import { UserTokenType } from "../../types/UserTokenType";
import { useFetchSplitOptions } from "../../hooks/useFetchSplitOptions";

const User = () => {
  const user = useBoundStore((state) => state.user);
  const setUser = useBoundStore((state) => state.setUser);

  const billing = useBoundStore((state) => state.billing);

  const setAppLoading = useBoundStore((state) => state.setAppLoading);

  const navigate = useNavigate();
  const location = useLocation();

  const setSelectedToken = useBoundStore((state) => state.setSelectedToken);
  const setUserTokens = useBoundStore((state) => state.setUserTokens);
  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);

  const { data: userData } = useQuery(USER);

  const [getUserTokenData, { data: tokensData }] =
    useLazyQuery(GET_USER_TOKENS);

  const fetchSplitOptions = useFetchSplitOptions();

  useEffect(() => {
    if (userData && userData.me && user.username === "") {
      setUser(userData.me);
      setAppLoading(true);
      getUserTokenData({
        onError(error) {
          console.log(error);
          openPopupMessage(error?.message, "error");
        },
        onCompleted() {
          setAppLoading(false);
        },
      });
    }
  });

  useEffect(() => {
    console.log("TOKENS");
    console.log(tokensData);
    if (tokensData && tokensData.tokens) {
      const tokens: UserTokenType[] = tokensData.tokens;
      setSelectedToken(tokens[0].accessToken);
      setUserTokens(tokens);

      if (!location.pathname.includes("billing")) {
        if (billing?.year?.toString() !== moment(new Date()).format("YYYY")) {
          fetchSplitOptions(tokens[0].accessToken, moment(new Date()).format("YYYY"), true);
        }
      }

      if (location.pathname.includes("preview")) {
        if (
          (billing?.year?.toString() !== moment(new Date()).format("YYYY"))
          && billing?.billingId > 0
        ) {
          fetchSplitOptions(tokens[0].accessToken, billing?.year?.toString() || moment(new Date()).format("YYYY"), true);
        }
      }

      if (tokens.length !== 1) {
        navigate("/");
      }
    }
  }, [fetchSplitOptions, navigate, setSelectedToken, setUserTokens, tokensData, location.pathname, billing?.year, billing?.billingId]);

  return (
    <>
      {userData?.me?.authorized ? (
        <Homepage />
      ) : (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </>
  );
};

export default User;
