import { Box } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useBoundStore } from "../../../utils/stores/BoundStore";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Loading from "../../../components/Loading";
import BillingForm from "../Billing/BillingForm";
import { GET_BILLING_PREVIEW } from "./gql";
import ErrorPage from "../../../components/ErrorPage";

const BillingPreview = () => {
  const { companyId, billingToken } = useParams();
  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);
  const selectedToken = useBoundStore((state) => state.selectedToken);
  const setAppLoading = useBoundStore((state) => state.setAppLoading);

  const billing = useBoundStore((state) => state.billing);
  const setBilling = useBoundStore((state) => state.setBilling);
  const setBillingToken = useBoundStore((state) => state.setBillingToken);
  const setCompanyId = useBoundStore((state) => state.setCompanyId);
  const setBillingDisabled = useBoundStore((state) => state.setBillingDisabled);
  const setBillingSplitType = useBoundStore(
    (state) => state.setBillingSplitType
  );

  const [
    getBillingPreview,
    {
      data: getBillingData,
      loading: getBillingLoading,
      error: getBillingError,
    },
  ] = useLazyQuery(GET_BILLING_PREVIEW);

  useEffect(() => {
    if (billingToken && selectedToken && companyId) {
      setBillingToken(billingToken);
      setCompanyId(companyId);
      setAppLoading(true);
      getBillingPreview({
        variables: {
          accessToken: selectedToken,
          billingToken: billingToken,
          companyId: companyId,
        },
        onError(error) {
          console.log(error.message);
          setAppLoading(false);
          openPopupMessage(error?.message, "error");
        },
        onCompleted(data) {
          console.log("GET BILLING DONE RESPONSE");
          console.log(data);
          setBilling(data.billingPreview);
          setAppLoading(false);
        },
      });
    }
  }, [
    billingToken,
    getBillingPreview,
    openPopupMessage,
    selectedToken,
    setAppLoading,
    setBilling,
  ]);

  useEffect(() => {
    setBillingDisabled(true);

    if (billing?.isSplitBilling === 0) {
      // setFormSteps(SPLIT_PER_RECEIPT_FORMSTEPS);
      setBillingSplitType("splitReceipt");
    } else {
      // setFormSteps(SPLIT_PER_BILLLING_FORMSTEPS);
      setBillingSplitType("splitBilling");
    }
  }, [billing, setBillingDisabled, setBillingSplitType]);

  if (getBillingError) {
    return <ErrorPage message={getBillingError.message} />;
  }

  if (getBillingLoading || !getBillingData) {
    return (
      <Box mt={2}>
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <BillingForm billing={billing} />
    </>
  );
};

export default BillingPreview;
