import {
  AppBar,
  Box,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import ResponsiveFullScreenModal from "../../../../components/layout/ResponsiveFullScreenModal";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode, useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { BillingType } from "../../../../types/BillingType";
import { appEnv } from "../../../Login";

const RadioStyledBox = ({
  activeColor,
  children,
}: {
  activeColor: string | null;
  children: ReactNode;
}) => {
  return (
    <Box
      sx={{
        border: "2px solid #ebebeb",
        borderRadius: "12px ",
        padding: "16px",
        marginBottom: "12px",
        borderColor: activeColor ? activeColor : "#ebebeb",
      }}
    >
      {children}
    </Box>
  );
};

interface BillingConfigProps {
  sendBilling: () => void;
  deleteBilling: (id: number) => void;
  isDeleteLoading: boolean;
  isUpdateLoading: boolean;
}

const BillingConfig = ({
  sendBilling,
  deleteBilling,
  isDeleteLoading,
  isUpdateLoading,
}: BillingConfigProps) => {
  let currentDate;
  if (appEnv === "production") {
    currentDate = moment();
  } else {
    currentDate = moment("2025-01-01");
  }
  const currentYear = currentDate.year();
  const previousYear = currentYear - 1;

  let years = [currentYear];

  const theme = useTheme();
  const billing = useBoundStore((state) => state.billing);
  const updateBilling = useBoundStore((state) => state.updateBilling);

  if (currentDate.isBefore(moment(`${currentYear}-01-16`))) {
    years = [previousYear, currentYear];
  }

  //make a condition to add to years array the years that are in the past of billing
  if (billing?.year && !years.includes(billing.year)) {
    years.push(billing.year);
  }

  const isUseAi = useBoundStore((state) => state.isUseAi);
  const setUseAi = useBoundStore((state) => state.setUseAi);

  const isBillingConfigOpen = useBoundStore(
    (state) => state.isBillingConfigOpen
  );

  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);
  const isBillingReturned = useBoundStore((state) => state.isBillingReturned);

  const setIsBillingConfigOpen = useBoundStore(
    (state) => state.setIsBillingConfigOpen
  );

  const [defaultBilling, setDefaultBilling] = useState<BillingType | null>(
    null
  );

  const handleDelete = () => {
    deleteBilling(billing?.billingId);
  };

  const handleSave = () => {
    sendBilling();
  };

  const handleConfigClose = () => {
    setIsBillingConfigOpen(false);

    if (defaultBilling) {
      updateBilling("name", defaultBilling.name);
      updateBilling("year", defaultBilling.year);
      updateBilling("currency", defaultBilling.currency);
      updateBilling("isSplitBilling", defaultBilling.isSplitBilling);
      //TODO: update back also isUsedScapi, neeeds to be implemeted in BE/helios first
    }
  };

  useEffect(() => {
    if (
      defaultBilling === null ||
      moment(billing.updatedAt).isAfter(moment(defaultBilling.updatedAt))
    ) {
      setDefaultBilling(billing);
    }
  }, [billing, defaultBilling]);

  return (
    <ResponsiveFullScreenModal isOpen={isBillingConfigOpen}>
      <AppBar
        sx={{
          position: "relative",
          background: "white",
          color: "black",
          marginBottom: "12px",
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h1" color="black">
            Nastavení
          </Typography>
          {!isBillingDisabled && (
            <Box mr={5}>
              <ConfirmDialog
                buttonLabel={<DeleteOutlineIcon />}
                color="inherit"
                dialogTitle="Smazání vyúčtování"
                dialogText="Opravdu si přeješ smazat vyúčtování?"
                handleConfirm={handleDelete}
                confirmButtonLabel="Smazat"
                confirmLoading={isDeleteLoading}
                isIcon={true}
                disabled={isBillingDisabled || isBillingReturned}
              />
            </Box>
          )}

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleConfigClose()}
            aria-label="close"
            data-testid="close-billing-config-modal"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Název"
              id="filled-size-small"
              value={billing?.name}
              variant="outlined"
              size="small"
              onChange={(e) => updateBilling("name", e.target.value)}
              disabled={isBillingDisabled || isBillingReturned}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="label-currency-select">Měna</InputLabel>
              <Select
                labelId="label-currency-select"
                id="currency-select"
                value={billing?.currency}
                label="Měna"
                onChange={(e) => updateBilling("currency", e.target.value)}
                disabled={isBillingDisabled || isBillingReturned}
              >
                <MenuItem value={"CZK"}>CZK</MenuItem>
                <MenuItem value={"EUR"}>EUR</MenuItem>
                <MenuItem value={"USD"}>USD</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="label-year-select">Rok</InputLabel>
              <Select
                labelId="label-year-select"
                id="year-select"
                value={billing?.year}
                defaultValue={currentYear}
                label="Rok"
                onChange={(e) => updateBilling("year", e.target.value)}
                disabled={isBillingDisabled || isBillingReturned}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              checked={isUseAi}
              control={
                <Switch color="primary" onChange={(e) => setUseAi(!isUseAi)} />
              }
              label="Automatické zpracování účtenek"
              labelPlacement="end"
              disabled={isBillingDisabled || isBillingReturned}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" mt={2} mb={2}>
              Kam zadám rozpočet:
            </Typography>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                defaultValue={0}
                name="radio-buttons-group"
                value={billing?.isSplitBilling}
                onChange={(e) =>
                  updateBilling("isSplitBilling", parseInt(e.target.value))
                }
                sx={{
                  "& .MuiTypography-root": {
                    width: "100%",
                  },
                  "& .MuiFormControlLabel-root": {
                    width: "100%",
                  },
                }}
              >
                <RadioStyledBox
                  activeColor={
                    billing?.isSplitBilling === 0
                      ? theme.palette.primary.main
                      : null
                  }
                >
                  <FormControlLabel
                    disabled={isBillingDisabled || isBillingReturned}
                    value={0}
                    control={<Radio />}
                    label={
                      <Grid
                        width="100%"
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={9} md={10}>
                          <>
                            <b>Rozpočty k </b>
                            <Typography
                              display="inline"
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: "bold",
                              }}
                            >
                              účtenkám
                            </Typography>
                            <br />
                            Moje účtenky jsou různého typu a jsou čerpány z
                            různých rozpočtů - zadám rozpočty ke každé účtence
                          </>
                        </Grid>
                        <Grid item xs textAlign={"center"}>
                          <ReceiptLongIcon
                            sx={{
                              fontSize: "3em",
                              marginRight: "-16px",

                              color:
                                billing?.isSplitBilling === 0
                                  ? "inherit"
                                  : "#ccc",
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </RadioStyledBox>

                <RadioStyledBox
                  activeColor={
                    billing?.isSplitBilling === 1
                      ? theme.palette.primary.main
                      : null
                  }
                >
                  <FormControlLabel
                    disabled={isBillingDisabled || isBillingReturned}
                    value={1}
                    control={<Radio />}
                    label={
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={9} md={10}>
                          <b>
                            Rozpočty k{" "}
                            <Typography
                              display="inline"
                              sx={{
                                color: theme.palette.primary.main,
                                fontWeight: "bold",
                              }}
                            >
                              vyúčtování
                            </Typography>
                          </b>
                          <br />
                          Moje účtenky jsou shodného typu a jsou čerpány z
                          jednoho rozpočtu - zadám jeden rozpočet za všechny
                          účtenky na vyúčtování
                        </Grid>
                        <Grid item xs textAlign={"center"}>
                          <RequestQuoteIcon
                            sx={{
                              fontSize: "3em",
                              marginRight: "-16px",
                              color:
                                billing?.isSplitBilling === 1
                                  ? "inherit"
                                  : "#ccc",
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </RadioStyledBox>
              </RadioGroup>
            </FormControl>
          </Grid>

          {!isBillingDisabled && !isBillingReturned && (
            <Grid item xs={12} textAlign="right">
              <LoadingButton
                variant="contained"
                startIcon={<CheckIcon />}
                disableElevation
                onClick={handleSave}
                loading={isUpdateLoading}
              >
                Uložit
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </ResponsiveFullScreenModal>
  );
};

export default BillingConfig;
