import { Alert } from "@mui/material";

const TempAlert = ({
  children,
  fromDate,
  dueDate,
  severity,
}: {
  children: React.ReactNode;
  fromDate: Date;
  dueDate: Date;
  severity?: "error" | "warning" | "info" | "success";
}) => {
  const currentDate = new Date();

  if (currentDate >= fromDate && currentDate <= dueDate) {
    return (
      <Alert
        severity={severity || "info"}
        sx={{
          borderRadius: "0",
        }}
      >
        {children}
      </Alert>
    );
  }

  return null;
};

export default TempAlert;
