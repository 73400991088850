import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import LoginImage from "../../images/login.png";
import { styled } from "@mui/material/styles";

const SeznamButton = styled(Button)({
  backgroundColor: "#cc0100",
  "&:hover": {
    backgroundColor: "#910403",
  },
  "&:active": {
    backgroundColor: "#910403",
  },
});

const createRedirectUrl = (destinationPath: string) =>
  encodeURI(
    process.env.REACT_APP_REDIRECT_URL +
    (destinationPath ? `?destinationPath=${destinationPath}` : "")
  );
const clientId = encodeURI(process.env.REACT_APP_CLIENT_ID || "");
const authorizeUrl = process.env.REACT_APP_AUTHORIZE_URL;

export const appEnv: string = process.env.REACT_APP_ENV_TYPE || "";

const ENVIRONMENT_TYPE_BACKGROUND: {
  [key: string]: { background: string; text: string };
} = {
  dev: {
    background: "#87f1f5",
    text: "DEVELOPMENT prostředí",
  },
  test: {
    background: "#f5df87",
    text: "TESTOVACÍ prostředí",
  },
  production: {
    background: "#F7F7F7",
    text: "",
  },
};

const Login = () => {
  const location = useLocation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
        padding: "24px",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <EnvronmentHeadline />
        <Grid item xs={12}>
          <Box
            sx={{
              "& img": {
                display: "block",
                maxWidth: "500px",
                width: "100%",
              },
            }}
          >
            <img src={LoginImage} alt="login" />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom variant="h1" data-testid="app-name-id">
            Carmen
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom align="center" mb={2}>
            Posílejte pracovní náklady k vyúčtování snadno a rychle
          </Typography>

          <SeznamButton
            href={`${authorizeUrl}?response_type=code&client_id=${clientId}&scope=user&redirect_uri=${createRedirectUrl(
              location.pathname + location.hash
            )}`}
            size="large"
            variant="contained"
            fullWidth
            disableElevation
          >
            Přihlásit se
          </SeznamButton>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" mt={1}>
            Copyright © {new Date().getFullYear() - 1}-
            {new Date().getFullYear()}, Seznam.cz, a.s.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const EnvronmentHeadline = (): React.JSX.Element => {
  if (appEnv === "production") return <></>;

  return (
    <Grid item>
      <Typography
        align="center"
        variant="h2"
        sx={{
          background: ENVIRONMENT_TYPE_BACKGROUND[appEnv].background || "#fff",
        }}
      >
        {ENVIRONMENT_TYPE_BACKGROUND[appEnv].text}
      </Typography>
    </Grid>
  );
};

export default Login;
