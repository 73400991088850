import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { ReceiptType } from "../../../../types/ReceiptType";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import { validateReceipt } from "./ReceiptValidation";
import DocumentThumbnail from "../../../../components/DocumentThumbnail/DocumentThumbnail";
import { replaceDotToComma } from "../../../../utils/strings";
import { STATES } from "../../../../utils/billing";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { checkIsReceiptEditable } from "../../../../utils/receipt";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

interface ReceiptEntryProps {
  receipt: ReceiptType;
  deleteReceipt: () => void;
  isDeleteLoading: boolean;
}

const ReceiptEntry = ({
  receipt,
  deleteReceipt,
  isDeleteLoading,
}: ReceiptEntryProps) => {
  const setIsReceiptFormOpen = useBoundStore(
    (state) => state.setIsReceiptFormOpen
  );
  const setReceiptFormAction = useBoundStore(
    (state) => state.setReceiptFormAction
  );
  const setReceipt = useBoundStore((state) => state.setReceipt);

  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);
  const isBillingReturned = useBoundStore((state) => state.isBillingReturned);

  const billingSplitType = useBoundStore((state) => state.billingSplitType);

  const handleDelete = (): void => {
    console.log("handleDelete");
    deleteReceipt();
  };

  const handleEdit = (): void => {
    setReceiptFormAction("edit");
    setReceipt(receipt);
    setIsReceiptFormOpen(true);
  };

  return (
    //FIXME: refactor the paper to EntryPaper component
    <>
      <Paper
        sx={{
          padding: "12px",
          // borderColor:
          //   receipt.state === STATES.RETURNED
          //     ? theme.palette.warning.main
          //     : "default",
        }}
        variant="outlined"
      >
        <Grid container spacing={1}>
          <Grid item sx={{ flex: 1 }}>
            <Typography
              sx={{ fontSize: "1.12em", fontWeight: "bold" }}
              data-testid="receipt-entry-ref"
            >
              Účtenka {receipt.receiptRef}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton sx={{ padding: 0 }} onClick={handleEdit}>
              {checkIsReceiptEditable(receipt) ? (
                <EditIcon />
              ) : (
                <VisibilityIcon />
              )}
            </IconButton>
          </Grid>
          {!isBillingDisabled && !isBillingReturned && (
            <Grid item ml={2}>
              <ConfirmDialog
                buttonLabel={<DeleteOutlineIcon />}
                color="inherit"
                dialogTitle="Smazání účtenky"
                dialogText="Opravdu si přeješ smazat účtenku?"
                handleConfirm={handleDelete}
                confirmButtonLabel="Smazat"
                confirmLoading={isDeleteLoading}
                isIcon={true}
                disabled={isBillingDisabled}
              />
            </Grid>
          )}
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item xs={9} md={10} container>
              <Grid item xs={2} md={1}>
                <DocumentThumbnail
                  isReuploadDisabled={!checkIsReceiptEditable(receipt)}
                  receipt={receipt}
                  fileManual={null}
                  fileId={receipt.receiptFiles[0]}
                />
              </Grid>

              <Grid item xs={10} md={10}>
                <Typography pl={1}>{receipt.note}</Typography>
              </Grid>
            </Grid>
            <Grid item textAlign="right">
              {replaceDotToComma(receipt.sum)}
              <br />
              <Box sx={{ fontSize: "14px", color: "grey" }}>
                {receipt.issueDate &&
                  moment(receipt.issueDate).format("DD.MM.YYYY")}
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              {billingSplitType === "splitReceipt" && (
                <>Rozpočty: {receipt.splits?.length}</>
              )}{" "}
              Podklady: {receipt.receiptSupportFiles?.length}
            </Grid>
            <Grid item>
              {receipt.state === STATES.RETURNED ? (
                <>
                  {validateReceipt(receipt, billingSplitType).isValid ? (
                    <ContactSupportIcon
                      color="warning"
                      sx={{ verticalAlign: "middle" }}
                    />
                  ) : (
                    <WarningIcon
                      color="warning"
                      sx={{ verticalAlign: "middle" }}
                    />
                  )}
                </>
              ) : (
                <>
                  {validateReceipt(receipt, billingSplitType).isValid ? (
                    <CheckIcon
                      color="success"
                      sx={{ verticalAlign: "middle" }}
                    />
                  ) : (
                    <WarningIcon
                      color="warning"
                      sx={{ verticalAlign: "middle" }}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ReceiptEntry;
