import { useCallback } from "react";
import { useLazyQuery, ApolloError } from "@apollo/client";
import { useBoundStore } from "../utils/stores/BoundStore";
import { GET_SPLIT_OPTIONS } from "../pages/Login/gql";

export const useFetchSplitOptions = () => {
  const setSplitOptions = useBoundStore((state) => state.setSplitOptions);
  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);
  const setFetchSplitOptionsLoading = useBoundStore((state) => state.setFetchSplitOptionsLoading);

  const [getSplitOptions] = useLazyQuery(GET_SPLIT_OPTIONS);

  const fetchSplitOptions = useCallback(
    async (token: string, year: string, clearCache = false) => {
      setFetchSplitOptionsLoading(true);

      try {
        const { data } = await getSplitOptions({
          variables: {
            accessToken: token,
            year,
          },
          fetchPolicy: clearCache ? "network-only" : "cache-first",
        });
        setSplitOptions(data.splitOptions);
      } catch (error) {
        if (error instanceof ApolloError) {
          openPopupMessage(error.message, "error");
        } else if (error instanceof Error) {
          openPopupMessage(error.message, "error");
        } else {
          console.error("Unexpected error type:", error);
          openPopupMessage("Unknown error occurred", "error");
        }
      } finally {
        setFetchSplitOptionsLoading(false);
      }
    },
    [getSplitOptions, openPopupMessage, setSplitOptions, setFetchSplitOptionsLoading]
  );

  return fetchSplitOptions;
};
