import { Button } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { heicTo } from "heic-to"
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import { useBoundStore } from "../utils/stores/BoundStore";

interface FileUploadProps {
  handleUploadFiles: (files: File[]) => void;
  multiple: boolean;
}

const FileUpload = ({ handleUploadFiles, multiple }: FileUploadProps) => {
  const maxFileSizeMB = 5;
  const maxImageFileSizeMB = 50;

  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);

  const checkFilesSize = (files: File[]) => {
    let isExceedingFileSize = false;

    files.forEach((fileItem) => {
      if (fileItem.type.includes("jpeg") || fileItem.type.includes("png")) {
        if (fileItem.size / 1000000 > maxImageFileSizeMB) {
          isExceedingFileSize = true;
        }
      } else {
        if (fileItem.size / 1000000 > maxFileSizeMB) {
          isExceedingFileSize = true;
        }
      }
    });

    return isExceedingFileSize;
  };

  const onDrop = async (acceptedFiles: File[]) => {
    let parsedFiles: (File | Promise<File>)[] = [];

    if (checkFilesSize(acceptedFiles)) {
      openPopupMessage("Nahrávaný soubor je příliš velký. Max 5 MB.", "error");
      return;
    }

    if (acceptedFiles.length > 10) {
      openPopupMessage("Najednou lze nahrát max 10 souborů.", "error");
      return;
    }

    const processFiles = async () => {
      for (const fileItem of acceptedFiles) {
        let processedFile = fileItem;

        if (fileItem.type.includes("heic")) {
          const convertedFile = await heicTo({
            blob: fileItem,
            type: "image/jpeg",
            quality: 0.5
          });

          const newFileName = fileItem.name.replace(/\.heic$/i, '.jpeg');
          processedFile = new File([convertedFile], newFileName, {
            type: "image/jpeg",
            lastModified: Date.now(),
          });
        }

        if (processedFile.type.includes("jpeg") || processedFile.type.includes("png")) {
          console.log("found image to compress " + processedFile.type);
          parsedFiles.push(compressImageFile(processedFile));
        } else {
          parsedFiles.push(processedFile);
        }
      }

      const files = await Promise.all(parsedFiles);
      handleUploadFiles(files);
    };

    await processFiles();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple,
  });

  const compressImageFile = async (file: File) => {
    const imageFile = file;
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 4,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      return compressedFile;
    } catch (error) {
      console.log(error);
      return file;
    }
  };

  return (
    <div {...getRootProps()}>
      <Button
        variant="contained"
        fullWidth
        disableElevation
        color="secondary"
        startIcon={<FileUploadIcon />}
      >
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here ...</p> : <>Nahrát</>}
      </Button>
    </div>
  );
};

export default FileUpload;
