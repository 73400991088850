import React, { useCallback, useEffect, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import {
  SplitOptionType,
  SplitOptionValue,
  isSplitOptionValue,
} from "../../../../../types/SplitOptionType";
import { SplitType } from "../../../../../types/SplitType";
interface SplitAutocompleteProps {
  split: SplitType;
  options: SplitOptionValue[];
  label: string;
  optionKey: keyof SplitType;
  handleUpdate: (key: keyof SplitType, value: string) => void;
  value: string;
  disabled: boolean;
  updateOptionsFilter: (
    key: keyof SplitOptionType,
    value: string | null
  ) => void;

  filterOptionKey: keyof SplitOptionType;
  removeFilter: (key: keyof SplitOptionType) => void;
  // split: SplitType
}
const SplitAutocomplete = ({
  split,
  options,
  optionKey,
  filterOptionKey,
  label,
  handleUpdate,
  value,
  disabled,
  updateOptionsFilter,
  removeFilter,
}: SplitAutocompleteProps) => {
  // const defaultOptionValue = "";
  const [inputValue, setInputValue] = useState("");

  // const handleValueChange = (event: any, newValue: string) => {
  //   handleUpdate(optionKey, newValue);
  //   // newValue ? newValue.value : defaultOptionValue
  // };

  const findOptionLabel = useCallback(
    (value: string) => {
      const foundOption = options.find(
        (optionItem) => optionItem.value === value
      );
      if (foundOption) {
        return foundOption.label + "";
      }

      const optionLabelKey = optionKey + "Label";
      if (split[optionLabelKey as keyof SplitType]) {
        return split[optionLabelKey as keyof SplitType] + "";
      }

      return value;
    },
    [optionKey, options, split]
  );

  useEffect(() => {
    if (value) {
      setInputValue(findOptionLabel(value));
    } else {
      // setInputValue("");
    }
    // if (inputValue === null || inputValue === "") {
    //   setInputValue("");
    //   // setInputValue(value);
    // }
  }, [findOptionLabel, inputValue, value]);

  return (
    <>
      <Autocomplete
        getOptionLabel={(option) => option.label || ""}
        disabled={disabled}
        // disableClearable={true}
        disablePortal
        // freeSolo
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        // inputValue={
        //   findOptionLabel(inputValue) !== ""
        //     ? findOptionLabel(inputValue)
        //     : inputValue
        // }
        // onInputChange={(event, newInputValue) => {
        //   // console.log(newInputValue);
        //   if (findOptionLabel(newInputValue) !== "") {
        //     setInputValue(findOptionLabel(newInputValue));
        //   } else {
        //     setInputValue(newInputValue);
        //   }
        // }}
        options={options}
        // value={values.splits[splitIndex][optionKey]}
        onChange={(e, newValue) => {
          // console.log(newValue);
          if (newValue === null) {
            // handleUpdate(optionKey, "");
            // updateOptionsFilter(filterOptionKey, null);
            removeFilter(filterOptionKey);
          }
          if (isSplitOptionValue(newValue)) {
            // console.log("setting new value" + newValue);
            handleUpdate(optionKey, newValue.value);
            updateOptionsFilter(filterOptionKey, newValue.value);
          }
          // updateOptionsFilter(filterOptionKey, newValue.value);
          // console.log(newValue);
        }}
        // onChange={handleValueChange}
        // value={
        //   value === null
        //     ? {
        //         value: "",
        //         label: "",
        //       }
        //     : {
        //         value: value,
        //         label: findOptionLabel(value),
        //       }
        // }

        // value={{ value: value, label: inputValue }} //funguje, ale posila na BE przdny string
        value={
          value === null
            ? { value: "", label: "" }
            : { value: value, label: findOptionLabel(inputValue) }
        }
        // value={{ value: value, label: findOptionLabel(value) }}
        // value={{ value: value, label: findOptionLabel(inputValue) }}
        // value={value}

        size="small"
        fullWidth
        renderInput={(params) => (
          <TextField
            // size="small"
            variant="outlined"
            {...params}
            label={label}
            // error={
            //   touched["splits"] &&
            //   touched["splits"][splitIndex] &&
            //   errors["splits"] &&
            //   errors["splits"][splitIndex] &&
            //   touched["splits"][splitIndex][optionKey] &&
            //   errors["splits"][splitIndex][optionKey]
            // }
            // helperText={
            //   touched["splits"] &&
            //   touched["splits"][splitIndex] &&
            //   errors["splits"] &&
            //   errors["splits"][splitIndex] &&
            //   touched["splits"][splitIndex][optionKey] &&
            //   errors["splits"][splitIndex][optionKey]
            // }
            // onBlur={() =>
            //   setFieldTouched(`splits.${splitIndex}.${optionKey}`, true)
            // }
          />
        )}
        isOptionEqualToValue={(option, value) => {
          if (
            value.value === "" ||
            value.label === "" ||
            value.label === null ||
            value.value === null
          )
            return false;

          return option.value === value.value;
        }}

        // isOptionEqualToValue={(option, value) => option.value === value}
        // disabled={disabled || isSubmitting}
      />
    </>
  );
};

export default SplitAutocomplete;
