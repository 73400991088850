import gql from "graphql-tag";

export const GET_CASHBOXFILE = gql`
  query cashboxFile($fileId: Float!) {
    cashboxFile(fileId: $fileId) {
      base64
      filename
      id
    }
  }
`;

export const GET_CASHBOXFILE_BILLINGPREVIEW = gql`
  query cashboxFileBillingPreview(
    $fileId: Float!
    $accessToken: String!
    $billingToken: String!
    $companyId: String!
  ) {
    cashboxFileBillingPreview(
      fileId: $fileId
      billingToken: $billingToken
      accessToken: $accessToken
      companyId: $companyId
    ) {
      base64
      filename
      id
    }
  }
`;
